.border{
border:solid 2px black;
}
.fields{
  margin-bottom: 20px;
}
.headers{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
button{
  color:white;
  background: dodgerblue;
  border-radius: 5px ;
  border: none;
  margin: 5px;
    padding: 10px;
}

.DownloadButton{
  padding: 20px;
  max-width: 300px;
  font-size:20px;
  display: flex;
  justify-self: center;
  margin: auto;
}
.blueBox{
  padding: 10px;
  background-color: dodgerblue;
  border-radius: 5px;
  color: white;
  text-align: center;
  margin-top: 10px;
}
.blueBox > p{
  margin: 0px;
}
.fileInput{
  display: flex;
  flex-direction: column;

  align-content: center;
  height: inherit;
}
.fileInput > * {
  display: inline-block;
}
.fileInput> p{
  margin: 0px;
  margin-bottom: 10px;
}
.fieldRow{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: fit-content;
}
.center{
  text-align: center;
}
.naam,.functie{
  width: 40%;
  margin: 10px 1.5% ;
}
.fieldRow > input{
  height: 1.3em;
  align-self: center;
 }
.number{
  width: 20px;
  margin: auto 5px auto 15px;
}
.options{
  display: flex;
  margin:10px 1.5% ;
}
.options > i{
 cursor: pointer;
  user-select: none;
}

.Page{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}
.logoChooser{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.logoOption{
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.firstRow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0px;
  align-items: center;
}
.popUp{
  background: white;
  width: 90%;
  height: max-content;
  margin: 40px 5%;
  left: 0;
  top:0;
  position: absolute;
  border: black 1px solid;
  border-radius: 5px;
  padding: 20px;
  background: lightgray;
}
.popUpText{
  font-family: Arial;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  line-height:1.3em;
 }
.popUpColums{
  position: relative;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 1fr;
  grid-gap: 5px;
}
.textColumn{
  grid-column: 1 / 2;
}
.selectColumn{
  grid-column: 2 / 3;
  background-color: gray;
  position:sticky;
  height: fit-content;
  top:100px;
  padding: 20px;
}
.selectColumn textarea{
  border-style: solid;
  border-width: 1.5px;
  max-width: 100%;
  min-width: 100%;
  height: 1.2em;
  font-family: Arial;
}
.downloadButtons{
  display: flex;
  flex-direction: row;
}